import { Component, Input, OnInit, OnChanges, AfterViewInit, SimpleChanges, ViewChild } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
import { HlCustomerSettingsModalComponent } from '../../../human-in-the-loop/hl-customer-settings-modal/hl-customer-settings-modal.component';
import { UserModel } from 'src/app/models';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BotConfigService } from 'src/app/services/bot-config.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerSettingsModel } from 'src/app/models/CustomerSettingsModel';
import { DumbledoreService, DoNotContact } from 'src/app/services/dumbledore.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

type ConversationHeader = {
  email: string;
  fullName: string;
  phone: string;
  vehicle: string;
  createdAt: number;
  displayDateTime: string;
  conversation_id: string;
  click: string;
};

@Component({
  selector: 'app-user-recent-conversations',
  templateUrl: './recent-conversations.component.html',
  styleUrls: ['./recent-conversations.component.scss'],
})
export class RecentConversationsComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(
    private apiGatewayAnalyticsService: ApiGatewayAnalyticsService,
    public humanInLoopService: HumanInLoopService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
    private authService: AuthService,
    private toaster: ToastrService,
    private dumbledoreService: DumbledoreService,
    private botConfigService: BotConfigService,
    private router: Router,
  ) {
    this.currentRoute = this.router.url;
  }

  lastQuery = '';
  displayedColumns = ['displayDateTime', 'fullName', 'email', 'phone', 'vehicle'];
  bot: any;
  reportDetails: any;
  displayDetails: MatTableDataSource<ConversationHeader>;
  hasMore: boolean;
  isLoading: boolean;
  currentUser: UserModel;
  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();
  @Input() reportType = 'T';
  @Input() showReport = false;
  selectedSettingsSubscription: Subscription;
  selectedConversationId: string;
  latestVersion = false;
  fordDealer = false;
  currentRoute = '';
  @ViewChild(MatPaginator) conversationPaginator: MatPaginator;
  @ViewChild('tableSort') conversationSort: MatSort = new MatSort();

  async onSearch(query: string = '') {
    if (!query || query === '') {
      await this.getReportDetails();
    } else {
      this.lastQuery = query;
      this.displayDetails.filter = query.toLowerCase();
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    const user = await this.authService.getCurrentUserProfile();
    if (!user) {
      throw new Error('No user profile found');
    }
    this.currentUser = user;
    if (this.showReport) {
      await this.getReportDetails();
    } else {
      this.reportDetails = [];
    }
    this.isLoading = false;
    if (this.botId) {
      this.bot = await this.botConfigService.getBotConfigById(this.botId);
      this.latestVersion = true;

      const botConfig = await this.dumbledoreService.getBotConfigurationByDealershipCode(this.bot.code);

      this.fordDealer = botConfig.fordDealer;
      if (this.fordDealer && !this.displayedColumns.includes('esmScore')) {
        this.displayedColumns.push('esmScore');
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngAfterViewInit(): void {
    if (this.displayDetails) {
      this.displayDetails.sort = this.conversationSort;
      this.displayDetails.paginator = this.conversationPaginator;
    }
  }

  formatPhoneNumber(value: string) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match1) {
      return '(' + match1[1] + ') ' + match1[2] + '-' + match1[3];
    }

    const match2 = cleaned.match(/^(\d{3})(\d{4})$/);

    if (match2) {
      return match2[1] + '-' + match2[2];
    }

    const match3 = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

    if (match3) {
      return '(' + match3[2] + ') ' + match3[3] + '-' + match3[4];
    }

    return value;
  }

  saveSessionParameters(event) {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = {
          startDate: this.startDate,
          endDate: this.endDate,
          reportType: this.reportType,
          filters: [],
          sortby: '',
        };
        storage.setItem('dashboardParameters', JSON.stringify(value));
      }
    } catch (err) {
      console.error('Failed storing messages', err);
    }
  }

  async getReportDetails() {
    this.hasMore = false;
    try {
      const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
        this.botId,
        this.startDate,
        this.endDate,
        this.reportType,
      );

      this.reportDetails = [];
      if (response.tables.report.details !== null) {
        let result = await Promise.all(
          response.tables.report.details.map(async details => {
            const id = 'db_conversation_id';
            const last_updated = 'db_last_updated';
            const fullName = 'full_name';
            const email = 'email';
            const vehicle = 'vehicle';
            const phone = 'phone';
            const click = 'first_engagement_occurred';
            const name: string = details[fullName];
            const esmScore = Math.round(details['esm_score']);

            return {
              email: details[email] !== '' ? details[email] : 'N/A',
              fullName: name && name.trim() !== '' ? name : 'N/A',
              phone: details[phone] ? this.formatPhoneNumber(details[phone]) : 'N/A',
              vehicle: details[vehicle] ? details[vehicle] : 'N/A',
              createdAt: new Date(details[last_updated]).getTime(),
              displayDateTime: new Date(details[last_updated]).toLocaleString(),
              conversation_id: details[id],
              click: details[click],
              esmScore: esmScore >= 7 ? 'HIGH' : esmScore >= 3 ? 'MEDIUM' : '---',
            };
          }),
        );
        result = result.sort((a, b) => b.createdAt - a.createdAt);
        this.reportDetails = result;
      }
      this.displayDetails = new MatTableDataSource<ConversationHeader>(this.reportDetails);
      setTimeout(() => {
        this.displayDetails.paginator = this.conversationPaginator;
        this.displayDetails.sort = this.conversationSort;
      }, 100);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }

  async selectRow(event) {
    if (event.click === true) {
      this.saveSessionParameters(event);
      const path = this.currentRoute.replace('dashboard', 'human-in-the-loop/' + event.conversation_id);
      this.router.navigateByUrl(path);
    } else {
      await this.selectCustomer(event);
    }
  }

  async selectCustomer(event) {
    if (!event.conversation_id) {
      return;
    }

    let email = '';

    if (event) {
      email = event.email ? event.email : '';
    }

    if (!this.currentUser) {
      const user = await this.authService.getCurrentUserProfile();
      if (user) {
        this.currentUser = user;
      }
    }
    if (!this.currentUser) {
      return;
    }

    const modalRef = this.modalService.show(HlCustomerSettingsModalComponent, {
      initialState: {
        conversation_id: event.conversation_id,
        bot: this.bot,
      },
    });

    if (this.selectedSettingsSubscription) {
      this.selectedSettingsSubscription.unsubscribe();
    }

    this.selectedSettingsSubscription = modalRef.content.selectedSettings.subscribe(
      ({ newCustomerSettings, originalCustomerSettings, currentCustomer }) => {
        this.updateCustomerSettings(
          currentCustomer.customer_id,
          currentCustomer.legacy_customer_id,
          newCustomerSettings,
          originalCustomerSettings,
          modalRef,
          email,
          event.conversation_id,
        );
      },
    );
    //}
  }

  async updateCustomerSettings(
    customerId: number,
    userId: string,
    newCustomerSettings: CustomerSettingsModel,
    originalCustomerSettings: CustomerSettingsModel,
    modalRef: BsModalRef,
    email: string,
    conversationId: string,
  ) {
    if (conversationId) {
      const botId = this.botId;
      const corpId = this.botId.split('-')[0];
      const botConfig = await this.botConfigService.getBotConfigById(botId);

      try {
        let wait = false;
        let updated = false;
        const doNotContact: { channelCode: string; type: DoNotContact; value: boolean }[] = [];
        if (newCustomerSettings.doNotContact !== originalCustomerSettings.doNotContact && email) {
          doNotContact.push({ channelCode: 'EMAIL', type: DoNotContact.DMS, value: newCustomerSettings.doNotContact });
        }

        if (newCustomerSettings.doNotContactInternal !== originalCustomerSettings.doNotContactInternal && email) {
          doNotContact.push({
            channelCode: 'EMAIL',
            type: DoNotContact.INTERNAL,
            value: newCustomerSettings.doNotContactInternal,
          });
        }

        if (newCustomerSettings.doNotContactSMS !== originalCustomerSettings.doNotContactSMS) {
          doNotContact.push({ channelCode: 'SMS', type: DoNotContact.DMS, value: newCustomerSettings.doNotContactSMS });
        }

        if (newCustomerSettings.doNotContactInternalSMS !== originalCustomerSettings.doNotContactInternalSMS) {
          doNotContact.push({
            channelCode: 'SMS',
            type: DoNotContact.INTERNAL,
            value: newCustomerSettings.doNotContactInternalSMS,
          });
        }

        if (newCustomerSettings.doNotContactFederal !== originalCustomerSettings.doNotContactFederal) {
          doNotContact.push({
            channelCode: 'SMS',
            type: DoNotContact.FEDERAL_DNC,
            value: newCustomerSettings.doNotContactFederal,
          });
        }

        if (newCustomerSettings.doNotContactLitigator !== originalCustomerSettings.doNotContactLitigator) {
          doNotContact.push({
            channelCode: 'SMS',
            type: DoNotContact.KNOWN_LITIGATOR,
            value: newCustomerSettings.doNotContactLitigator,
          });
        }

        if (doNotContact && doNotContact.length > 0) {
          const res = await this.dumbledoreService.setDoNotContact(customerId, conversationId, doNotContact);
          updated = true;
        }

        if (newCustomerSettings.stopCurrentSeries && !originalCustomerSettings.stopCurrentSeries) {
          if (wait) {
            setTimeout(() => {
              console.log('Waiting for the DNC update to finish.');
            }, 500);
          }
          await this.dumbledoreService.stopCampaignProgress(conversationId);
          this.toasterService.success('The selected conversation was stopped.');
          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            'Stopped Current Series',
            this.currentUser.fullName,
          );
        }

        if (newCustomerSettings.inactive !== originalCustomerSettings.inactive) {
          if (wait) {
            setTimeout(() => {
              console.log('Waiting for the previous update to finish.');
            }, 500);
          }
          await this.dumbledoreService.setVehicleInactive(conversationId, newCustomerSettings.inactive);
          if (newCustomerSettings.inactive) {
            this.toasterService.success('The selected vehicle was updated - inactive.');
          } else {
            this.toasterService.success('The selected vehicle was updated - active.');
          }

          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            newCustomerSettings.inactive ? 'Customer sold vehicle.' : 'Customer vehicle reinstated.',
            this.currentUser.fullName,
          );

          wait = true;
        }

        if (
          newCustomerSettings.firstName !== originalCustomerSettings.firstName ||
          newCustomerSettings.lastName !== originalCustomerSettings.lastName ||
          newCustomerSettings.sms !== originalCustomerSettings.sms ||
          newCustomerSettings.phone !== originalCustomerSettings.phone ||
          newCustomerSettings.email !== originalCustomerSettings.email ||
          newCustomerSettings.channel !== originalCustomerSettings.channel
        ) {
          if (wait) {
            setTimeout(() => {
              console.log('Waiting for the previous update to finish.');
            }, 500);
          }
          await this.dumbledoreService.updateCustomerSettings(userId, newCustomerSettings);

          if (newCustomerSettings.firstName !== originalCustomerSettings.firstName) {
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `First name changed from ${originalCustomerSettings.firstName} to ${newCustomerSettings.firstName} .`,
              this.currentUser.fullName,
            );
          }

          if (newCustomerSettings.lastName !== originalCustomerSettings.lastName) {
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `Last name changed from ${originalCustomerSettings.lastName} to ${newCustomerSettings.lastName} .`,
              this.currentUser.fullName,
            );
          }

          if (newCustomerSettings.email !== originalCustomerSettings.email) {
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `SMS changed from ${originalCustomerSettings.email} to ${newCustomerSettings.email} .`,
              this.currentUser.fullName,
            );
          }

          if (newCustomerSettings.sms !== originalCustomerSettings.sms) {
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `SMS changed from ${originalCustomerSettings.sms} to ${newCustomerSettings.sms} .`,
              this.currentUser.fullName,
            );
          }

          if (newCustomerSettings.phone !== originalCustomerSettings.phone) {
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `Phone changed from ${originalCustomerSettings.phone} to ${newCustomerSettings.phone} .`,
              this.currentUser.fullName,
            );
          }

          if (newCustomerSettings.channel !== originalCustomerSettings.channel) {
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `Preferred conctact changed from ${originalCustomerSettings.channel} to ${newCustomerSettings.channel} .`,
              this.currentUser.fullName,
            );
          }
        }

        await this.humanInLoopService.touchConversation(corpId, conversationId);

        modalRef.hide();
        return this.toasterService.success('Customer records were updated successfully.');
      } catch (error) {
        modalRef.hide();
        return this.toasterService.error('Could update customer records.');
      }
    }

    this.toasterService.error('Something went wrong... please try again later.');
  }
}
