import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { HierarchyElementModel } from 'src/app/models';
import { CorpsService, HierarchyElementsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-hierarchy-element-analytics',
  templateUrl: './hierarchy-element-analytics.component.html',
  styleUrls: ['./hierarchy-element-analytics.component.scss'],
})
export class HierarchyElementAnalyticsComponent implements OnInit, OnDestroy {
  private crtDataSubscription: Subscription;
  private paramMapSubscription: Subscription;
  loading: boolean;
  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private hierarchyElementsService: HierarchyElementsService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      ([params, user]) => {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        if (!hierarchyElementSystemName || !corpId || !user) {
          return;
        }
        const fullHierarchyElementSystemName = `${corpId}-${hierarchyElementSystemName}`;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(fullHierarchyElementSystemName),
        ]).subscribe(([corp, hierarchyElement]) => {
          if (!corp || !hierarchyElement) {
            return;
          }
          this.loading = true;
          this.corp = corp;
          this.hierarchyElement = hierarchyElement;

          this.refreshUI();
        });
      },
    );
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.hierarchyElement.label} Analytics`);
    this.setBreadcrumb(this.corp, this.hierarchyElement);
    this.setSidebarItems(this.corp.id, this.hierarchyElement);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, 'Analytics', 'analytics'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
