import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems } from '../utils';
import { CorpsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-corp-analytics',
  templateUrl: './corp-analytics.component.html',
  styleUrls: ['./corp-analytics.component.scss'],
})
export class CorpAnalyticsComponent implements OnInit, OnDestroy {
  loading = false;

  private crtCorpSubscription: Subscription;
  private paramMapSubscription: Subscription;
  corp: CorpModel;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private authService: AuthService,
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        const user = results1[1];
        const corpId = params.get('corp');
        if (!corpId || !user) {
          return;
        }
        this.crtCorpSubscription = combineLatest([this.corpsService.getCorpById(corpId)]).subscribe(([corp]) => {
          if (!corp) {
            return;
          }
          this.corp = corp;
          this.loading = false;

          this.refreshUI();
        });
      },
    );
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.corp.label} Analytics`);
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
  }

  private setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: corp.label,
        icon: corp.logo,
        route: `portal/corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
      {
        label: 'Analytics',
        route: `portal/corps/${corp.id}/analytics`,
      },
    ]);
  }

  private setSidebarItems(corpId: string) {
    this.sidebarService.set(getSidebarItems(corpId));
  }

  ngOnDestroy() {
    if (this.crtCorpSubscription) {
      this.crtCorpSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
