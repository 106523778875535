import { VisualTemplateEditorGroupComponent } from '../group/visual-template-editor-group.component';
import { Component, ComponentFactoryResolver, EventEmitter, Injector, Output } from '@angular/core';
import { VisualTemplateBase } from '../visual-template-base';

@Component({
  selector: 'app-visual-template-editor-template',
  templateUrl: './visual-template-editor-template.component.html',
  styleUrls: ['./visual-template-editor-template.component.scss']
})
export class VisualTemplateEditorTemplateComponent extends VisualTemplateBase {
  public availableElements = [
    {
      name: 'group',
      component: VisualTemplateEditorGroupComponent
    }
  ];

  @Output() templateSaved = new EventEmitter();

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public injector: Injector) {
    super(componentFactoryResolver, injector);
  }
}
