export class TopicNode {
  index: number;
  name: string;
  synonyms: string[];
  message: string;
  children: TopicNode[];

  constructor() {
    this.children = [];
  }
}
