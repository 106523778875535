import {
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Injector,
  Input,
  Type,
  OnInit,
  AfterContentInit,
  Directive,
} from '@angular/core';

@Directive()
export abstract class VisualTemplateBase implements AfterViewInit {
  protected availableElements: Array<{
    name: string;
    component: typeof VisualTemplateBase;
  }> = [];

  protected removeQuotesOf: string[] = [];

  protected constructor(protected componentFactoryResolver: ComponentFactoryResolver, protected injector: Injector) {}

  @Input()
  public data: any;
  public destroy: any;

  @ViewChild('innerElements', { read: ViewContainerRef }) viewComponent;

  ngAfterViewInit() {
    this.data = Array.isArray(this.data) ? this.data[0] : this.data;
    this.data.body = this.data.body ?? [];
    this.data.params = this.data.params ?? {};
    this.data.isFinal = !this.availableElements.length;
    this.createChildrenFromPayload();
  }

  addComponentByName(componentName: string): any {
    return this.addComponent({ element: componentName, params: {} });
  }

  addComponent(data): any {
    const component = this.getComponentTypeByName(data.element);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<VisualTemplateBase>(component);
    const componentRef = componentFactory.create(this.injector);
    componentRef.instance.data = data;
    componentRef.instance.destroy = () => this.onChildDestroy(componentRef);
    this.viewComponent.insert(componentRef.hostView);

    if (!this.data.body.some(_ => _.guid === data.guid)) {
      this.data.body.push(componentRef.instance.data);
    }
  }

  getComponentTypeByName(name: string): Type<VisualTemplateBase> {
    return this.availableElements.find(_ => _.name === name)?.component as Type<VisualTemplateBase>;
  }

  createChildrenFromPayload(): void {
    if (!this.data.body) {
      return;
    }
    for (const child of this.data.body) {
      this.addComponent(child);
    }
  }

  onChildDestroy(child): any {
    this.data.body = this.data.body.filter(_ => _.guid !== child.instance.data.guid);
    this.viewComponent.remove(this.viewComponent.indexOf(child));
  }
}
