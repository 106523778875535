import { stagingSecrets } from './secrets.staging.local';

export const environment = {
  production: false,
  useAuth0: true,
  vulcan: {
    url: 'https://ck20l9zj65.execute-api.us-east-1.amazonaws.com/prod/',
  },
  botConfig: {
    url: 'https://y7e6298lif.execute-api.us-east-1.amazonaws.com/staging/bot-configuration/',
    latestVersion: 4,
  },
  dumbledore: {
    url: 'https://dsrz04qrp6.execute-api.us-east-1.amazonaws.com/prod/',
  },
  bach: {
    url: 'https://x3e1n8e1f9.execute-api.us-east-1.amazonaws.com/prod/',
  },
  firebase: {
    apiKey: 'AIzaSyDXkg7ZRDFqWU24dPXXyKyOVLETFEAxTPI',
    authDomain: 'brain-ui-v1-staging.firebaseapp.com',
    databaseURL: 'https://brain-ui-v1-staging.firebaseio.com',
    projectId: 'brain-ui-v1-staging',
    storageBucket: 'brain-ui-v1-staging.appspot.com',
    messagingSenderId: '103271147830',
    appId: '1:103271147830:web:86bd760c675fd8fb4f6b97',
    measurementId: 'G-7H8X09HJ0L',
  },
  es: {
    'us-east-1': {
      domain: 'https://logs.staging-es.car-labs.com/',
      accessKeyId: 'AKIATZ3SGK5WRBLWYDFG',
      secretAccessKey: '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',
    },
    'ap-south-1': {
      domain: 'https://logs.staging-es.car-labs.com/',
      accessKeyId: 'AKIATZ3SGK5WRBLWYDFG',
      secretAccessKey: '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',
    },
  },
  brain: {
    url: 'https://api.brain-staging.car-labs.com',
  },
  apiGatewayAnalytics: {
    url: 'https://ax6z2w9cqe.execute-api.us-east-1.amazonaws.com/',
    url1: 'http://localhost:3000/',
  },
  apiGatewayService: {
    url: 'https://api-staging.data.car-labs.com',
  },
  botWebChatUrl: 'https://webchat.staging.car-labs.com/static/js/carlabs-bundle.js',
  nlpDojoUrl: 'https://nlp-dojo.staging.car-labs.com',
  nlpDojoS3Url: 'https://cl-nlp-dojo-staging.s3.amazonaws.com',
  jaiminhoEmailService: {
    url: 'https://g54z63ifcb.execute-api.us-east-1.amazonaws.com/staging/message',
  },
  platformApi: {
    url: 'https://qqukg4qxdg.execute-api.us-east-1.amazonaws.com/prod',
  },
  shopbot: {
    url: 'https://shopbot.staging.car-labs.com',
  },
  domain: 'dev-d8wrdqvexeqghnvi.us.auth0.com',
  clientId: 'w7DA97ftw6eQxk1cHDpCMgnEVoemoXHV',
  authSecrets: stagingSecrets,
};
