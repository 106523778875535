import { Component, OnInit, Input } from '@angular/core';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  pageTitle: string | null;

  @Input()
  backgroundColor = 'primary';

  constructor(private headerService: HeaderService) {}

  ngOnInit() {
    this.headerService.pageTitle$.subscribe(pageTitle => {
      this.pageTitle = pageTitle;
    });
  }
}
