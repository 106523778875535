import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsListItemProgressColors } from '../_types/AnalyticsListItem';

@Component({
  selector: 'app-analytics-progress-data',
  templateUrl: './analytics-progress-data.component.html',
  styleUrls: ['./analytics-progress-data.component.scss'],
})
export class AnalyticsProgressDataComponent implements OnInit {
  @Input() height: number = 20;
  @Input() progress: number = 0;
  @Input() progressColor: AnalyticsListItemProgressColors = 'blue';

  constructor() {}

  ngOnInit() {}
}
