import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HLTemplateModel } from 'src/app/models/conversations';
import { v4 as uuidv4 } from 'uuid';
import { TemplateChannels } from 'src/app/models/template';

@Component({
  selector: 'app-hl-conversation-create-template',
  templateUrl: './hl-conversation-create-template.component.html',
  styleUrls: ['./hl-conversation-create-template.component.scss'],
})
export class HlConversationCreateTemplateComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    editable: true,
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'strikeThrough',
        'redo',
        'superscript',
        'subscript',
        'backgroundColor',
        'textColor',
        'insertVideo',
        'insertImage',
      ],
      ['fontSize', 'fontName'],
    ],
  };

  channels = [
    { id: TemplateChannels.Email, name: 'Email' },
    { id: TemplateChannels.SMS, name: 'SMS' },
  ];
  template: HLTemplateModel;

  saved: EventEmitter<HLTemplateModel>;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    this.template = new HLTemplateModel();
    this.template.id = uuidv4();
    this.saved = new EventEmitter<HLTemplateModel>();
  }

  saveTemplate() {
    if (!this.template.name) {
      alert('Enter template name');
      return;
    }

    if (!this.template.channel) {
      alert('Enter template channel');
      return;
    }
    if (!this.template.content) {
      alert('Enter template content');
      return;
    }
    this.saved.emit(this.template);
  }

  ngOnInit() {}
}
