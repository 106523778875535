export class SunburstTree {
  name: string;
  color: string;
  size?: number;
  children: SunburstTree[];

  constructor(name: string, size: number = 0) {
    this.name = name;
    this.size = size;
    this.children = [];
  }

  public increaseSize(count: number) {
    if (this.size === undefined) {
      this.size = 0;
    }
    this.size += count;
  }

  getChildByName(name: string): SunburstTree | undefined {
    return this.children.find(child => child.name == name);
  }
}
