import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import Sunburst from 'sunburst-chart';
import { Observable, Subscription } from 'rxjs';
import { SunburstData } from './_types/SunburstData';
import { ToastrService } from 'ngx-toastr';
import * as d3 from 'd3';
import { TimeInterval } from 'src/app/components/analytics/_types/TimeInterval';
import { TimeIntervalType } from 'src/app/components/analytics/_types/TimeIntervalType';
import { getIndexes } from 'src/app/utils/es-reactive/es-reactive';

@Component({
  selector: 'app-sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.scss']
})
export class SunburstComponent implements OnChanges, OnDestroy {
  loading: boolean;

  @ViewChild('sbChart', { static: true }) sbChartEl: ElementRef;

  @Input()
  title: string;

  @Input()
  surtitle?: string;

  @Input()
  source: (indexes: string[]) => Observable<SunburstData>;

  timeIntervals: TimeIntervalType[] = ['1d', '5d', '7d', '1M', '6M', '1y', 'YTD', 'MAX'];
  timeFrames: TimeInterval[];
  selectedTimeFrame: TimeInterval | null;

  private sourceSubscription: Subscription;

  constructor(private toaster: ToastrService) {}

  ngOnChanges() {
    this.timeFrames = this.timeIntervals.map(tf => new TimeInterval(tf));
    this.selectedTimeFrame =
      this.timeFrames && this.timeFrames.length
        ? (this.timeFrames.length > 4 ? this.timeFrames[3] : this.timeFrames[0])
        : null;

    const indexes = getIndexes(this.selectedTimeFrame?.getDatesAsString());
    this.initSourceSubscription(indexes);
  }

  private initSourceSubscription(indexes: string[]) {
    this.sourceUnsubscribe();
    this.loading = true;
    this.sourceSubscription = this.source(indexes).subscribe(
      data => {
        this.update(data);
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.toaster.error(error);
      }
    );
  }

  private update(data: SunburstData) {
    const color = d3.scaleOrdinal(d3.schemePaired);
    const sbChart = Sunburst();
    this.sbChartEl.nativeElement.innerHTML = '';

    sbChart
      .data(data.sunburstTree)
      .size('size')
      .width(700)
      .height(700)
      .excludeRoot(false)
      .showLabels(true)
      .tooltipContent((d, node) => `Size: <i>${node.value}</i>`)
      .color(d => color(d.name))(this.sbChartEl.nativeElement);
  }

  processTimeFrameChange(timeFrame: TimeInterval) {
    const indexes = getIndexes(timeFrame.getDatesAsString());
    this.initSourceSubscription(indexes);
  }

  private sourceUnsubscribe() {
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.sourceUnsubscribe();
  }
}
