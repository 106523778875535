import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { TopicNode } from 'src/app/components/bot/topic-editor/topic-node/TopicNode';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { HierarchyElementModel } from 'src/app/models';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';

@Component({
  selector: 'app-bot-editor',
  templateUrl: './bot-editor.component.html',
  styleUrls: ['./bot-editor.component.scss'],
})
export class BotEditorComponent implements OnInit, OnDestroy {
  private crtDataSubscription: Subscription;
  rootTopicNodes: TopicNode[] | null;

  private paramMapSubscription: Subscription;
  private sheetsUrl: string;
  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;
  bot: BotModel;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private botsService: BotsService,
    private authService: AuthService,
    private fns: AngularFireFunctions,
    private hierarchyElementsService: HierarchyElementsService,
    private toaster: ToastrService,
    private corpsService: CorpsService,
    private clientEnvironmentService: ClientEnvironmentService,
  ) {
    this.rootTopicNodes = null;
  }

  ngOnInit() {
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(([params, user, envs]) => {
      const corpId = params.get('corp');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      if (!corpId || !hierarchyElementSystemName || !botCode || !user || !envs) {
        return;
      }
      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
        this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
      ]).subscribe(([corp, hierarchyElement, bot]) => {
        if (!corp || !hierarchyElement || !bot) {
          return;
        }
        this.corp = corp;
        this.hierarchyElement = hierarchyElement;
        this.bot = bot;
        this.refreshUI();
        this.sheetsUrl = this.bot.config.googleSheetUrl.substring(this.bot.config.googleSheetUrl.indexOf('d/') + 2);

        this.getSpreadSheetData(this.sheetsUrl);
      });
    });
  }

  private getSpreadSheetData(sheetsUrlParam: string) {
    const callable = this.fns.httpsCallable('api/getSpreadSheetData');
    const getSpreadSheetData$ = callable({ sheetsUrl: sheetsUrlParam });
    getSpreadSheetData$.toPromise().then(
      response => {
        if (
          !response ||
          !response.data ||
          !response.data.valueRanges ||
          !response.data.valueRanges.length ||
          !response.data.valueRanges[0].values
        ) {
          return;
        }
        this.toaster.success('Spreadsheet data loaded successfully');
        this.getTopics(response.data.valueRanges[0].values.slice(1));
      },
      error => {
        this.toaster.error(error);
      },
    );
  }

  refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
    this.headerService.setPageTitle(`${this.bot.label} Editor`);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Editor', 'editor'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }

  private getTopics(values: any[][]) {
    this.rootTopicNodes = [];
    values.forEach((value, index) => {
      if (!value[0] || value[0].startsWith('#')) {
        return;
      }

      const topicNode = new TopicNode();
      topicNode.index = index;
      topicNode.name = value[0];
      if (value[8] && value[8] !== '' && value[8] !== '\n') {
        const replacedValue = value[8].replace(/ |\n/g, '');
        topicNode.synonyms = replacedValue.split(',');
      }
      topicNode.message = value[15];
      const parentName = value[1];
      if (parentName && this.rootTopicNodes && this.rootTopicNodes.length) {
        const parentNode = this.getParent(parentName, this.rootTopicNodes[0]);
        if (parentNode) {
          parentNode.children.push(topicNode);
        }
      } else if (this.rootTopicNodes) {
        this.rootTopicNodes.push(topicNode);
      }
    });
  }

  private getParent(parentName: string, crtNode: TopicNode): TopicNode | null {
    if (!crtNode) {
      return null;
    }
    if (crtNode.name === parentName) {
      return crtNode;
    }
    for (const childNode of crtNode.children) {
      const parent = this.getParent(parentName, childNode);
      if (parent) {
        return parent;
      }
    }
    return null;
  }

  saveNode(node: TopicNode) {
    if (this.sheetsUrl) {
      const data: any = [];
      const synonymsString = node.synonyms ? node.synonyms.join(', ') : '';
      data.push(
        {
          range: `\'Shop Bot Topics\'!I${node.index + 2}`,
          values: [[synonymsString]],
        },
        {
          range: `\'Shop Bot Topics\'!P${node.index + 2}`,
          values: [[node.message]],
        },
      );

      this.updateSpreadSheetNodes(data);
    }
  }

  private updateSpreadSheetNodes(nodes: any[]) {
    const callable = this.fns.httpsCallable('api/updateSpreadSheetData');
    const getSpreadSheetData$ = callable({
      sheetsUrl: this.sheetsUrl,
      updatedNodes: nodes,
    });
    getSpreadSheetData$.toPromise().then(
      response => {
        if (response.status === 200) {
          this.toaster.success('Node updated');
        }
      },
      err => {
        this.toaster.error(err);
      },
    );
  }
}
