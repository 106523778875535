import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TopicNode } from './topic-node/TopicNode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-topic-editor',
  templateUrl: './topic-editor.component.html',
  styleUrls: ['./topic-editor.component.scss']
})
export class TopicEditorComponent implements OnInit {
  @Input()
  nodes: TopicNode[];

  @Output()
  saveNode: EventEmitter<TopicNode>;

  selectedNode: TopicNode;

  options = {};

  constructor(private toaster: ToastrService) {
    this.saveNode = new EventEmitter<TopicNode>();
  }

  ngOnInit() {}

  selectNode(event) {
    this.selectedNode = event.node.data as TopicNode;
  }

  save() {
    this.saveNode.emit(this.selectedNode);
    this.toaster.success('Node saved successfully');
  }
}
